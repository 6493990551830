"use client";

import React from "react";
import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import useAuthFormStore from "@/store/useAuthFormStore";
import { useRouter } from "next/navigation";
import { FloatingLabelInput } from "../ui/floating-label";
import { Button } from "../ui/button";
import { login } from "@/app/(auth)/login/actions";
import ButtonLoader from "../shared/ButtonLoader";
// import { Turnstile } from "@marsidev/react-turnstile"
import { useApolloClient, useMutation } from "@apollo/client";
import { CreateUserDocument, CreateUserMutation, CreateUserMutationVariables } from "@/graphql/generated/types";
// import { useUser } from "@/contexts/user"

const formSchema = z.object({
  email: z.string().email({
    message: "Please enter a valid email address"
  }),
  password: z.string().min(1, {
    message: "Please enter a valid password"
  })
});

// const TURNSTILE_SITE_KEY = process.env.NEXT_PUBLIC_TURNSTILE_SITE_KEY!

const EmailLoginForm = () => {
  const router = useRouter();
  const email = useAuthFormStore(state => state.email);
  const setEmail = useAuthFormStore(state => state.setEmail);
  const resetStep = useAuthFormStore(state => state.resetStep);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  // const [captchaToken, setCaptchaToken] = React.useState("")
  const apolloClient = useApolloClient();
  const [createUser] = useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: "onBlur",
    defaultValues: {
      email: email,
      password: ""
    }
  });
  const handleForgotPassword = () => {
    resetStep();
    router.push("?reset_password=true&action=forgot_password");
  };
  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true);
    setEmail(values.email);
    const formData = new FormData();
    formData.append("email", values.email);
    formData.append("password", values.password);
    // formData.append("captchaToken", captchaToken)

    try {
      const response = await login(formData);
      if (!response?.error_code) {
        await apolloClient.resetStore();
        // window.location.href = "/"
        setIsLoading(false);
      } else if (response?.error_code === "invalid_credentials") {
        setErrorMessage("Invalid email or password");
        setIsLoading(false);
      } else if (response?.error_code === "email-not-found") {
        await createUser({
          variables: {
            input: {
              email: values.email
            }
          }
        });
        router.push("/signup?action=account_setup");
      } else {
        console.log(response?.error_code);
        setErrorMessage("Something went wrong. Please try again.");
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
    setIsLoading(false);
  }
  return <>
      {/* <Turnstile
        siteKey={TURNSTILE_SITE_KEY}
        onSuccess={(token) => {
          setCaptchaToken(token)
        }}
       /> */}
      <Form {...form} data-sentry-element="Form" data-sentry-source-file="EmailLoginForm.tsx">
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 sm:flex sm:flex-col">
          <FormField control={form.control} name="email" render={({
          field
        }) => <FormItem>
                <FormControl>
                  <FloatingLabelInput className="sm:w-96" isInvalid={form.getFieldState("email").invalid} id="email" label="Email" {...field} />
                </FormControl>
                <FormMessage className="!mt-0" role="alert" />
              </FormItem>} data-sentry-element="FormField" data-sentry-source-file="EmailLoginForm.tsx" />
          <FormField control={form.control} name="password" render={({
          field
        }) => <FormItem>
                <FormControl>
                  <FloatingLabelInput className="sm:w-96" id="password" type="password" label="Password" isInvalid={form.getFieldState("password").invalid} {...field} />
                </FormControl>
                <FormMessage className="!mt-0" role="alert" />
              </FormItem>} data-sentry-element="FormField" data-sentry-source-file="EmailLoginForm.tsx" />

          {errorMessage && <div className="text-sm !mt-1 text-red-400">{errorMessage}</div>}

          <div className="!mt-2 sm:self-end max-sm:mt-4  ">
            <Button type="button" onClick={handleForgotPassword} variant="link" className="p-0 text-sm font-medium text-link sm:underline" data-sentry-element="Button" data-sentry-source-file="EmailLoginForm.tsx">
              Forgot Password?
            </Button>
          </div>
          <Button disabled={isLoading} variant="primary" className="mt-9 h-12" type="submit" data-sentry-element="Button" data-sentry-source-file="EmailLoginForm.tsx">
            {isLoading ? <ButtonLoader /> : "Log in"}
          </Button>
        </form>
      </Form>
    </>;
};
export default EmailLoginForm;