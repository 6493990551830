"use client";

import React from "react";
import { Button } from "../ui/button";
import { cn } from "@/lib/utils";
// import { signOut as signOutFromServer } from "@/app/(auth)/login/actions"
import { useResponsive } from "@/hooks/useResponsive";
import { createClient } from "@/lib/supabase/client";
// import { useApolloClient } from "@apollo/client"
// import { revalidate } from "../account/actions"
// import { useRouter } from "next/navigation"

const LogoutButton = (props: React.ComponentProps<typeof Button>) => {
  const {
    className,
    ...rest
  } = props;
  // const router = useRouter()
  const {
    isMobile
  } = useResponsive();
  // const supabase = createClient()

  const signOut = async () => {
    const supabase = createClient();
    // await signOutFromServer()
    const {
      error
    } = await supabase.auth.signOut();
    if (error) return {
      error_code: error.code
    };else return {
      success: true
    };
  };
  const handleSignout = async () => {
    try {
      const result = await signOut();
      if (result?.success) {
        window.location.href = "/";
      } else {
        console.error("Error signing out:", result?.error_code);
      }
    } catch (err) {
      console.error(err);
    }
  };
  return <Button onClick={handleSignout} type="button" variant="link" className={cn("flex items-center cursor-pointer hover:no-underline justify-start h-fit w-full text-left gap-2 p-0 text-sm font-medium text-white", className)} {...rest} data-sentry-element="Button" data-sentry-component="LogoutButton" data-sentry-source-file="LogoutButton.tsx">
      {isMobile ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.62134 5.15016C6.17153 6.11229 5.07006 7.51569 4.48 9.15259C3.88993 10.7895 3.84263 12.5729 4.3451 14.2388C4.84757 15.9047 5.87311 17.3645 7.26988 18.4021C8.66665 19.4397 10.3604 20 12.1004 20C13.8404 20 15.5342 19.4397 16.931 18.4021C18.3277 17.3645 19.3533 15.9047 19.8557 14.2388C20.3582 12.5729 20.3109 10.7895 19.7208 9.15259C19.1308 7.51569 18.0293 6.11229 16.5795 5.15016M12.1009 2V9.20037" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg> : <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.26763 4.125C4.05952 4.92673 3.14167 6.09617 2.64998 7.46019C2.15828 8.8242 2.11887 10.3103 2.53757 11.6985C2.95627 13.0866 3.81084 14.3031 4.97476 15.1677C6.13867 16.0323 7.55007 16.4992 9 16.4992C10.4499 16.4992 11.8613 16.0323 13.0252 15.1677C14.1892 14.3031 15.0437 13.0866 15.4624 11.6985C15.8811 10.3103 15.8417 8.8242 15.35 7.46019C14.8583 6.09617 13.9405 4.92673 12.7324 4.125M9.00038 1.5V7.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>}
      Log out
    </Button>;
};
export default LogoutButton;