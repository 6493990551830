"use server"

import { revalidatePath } from "next/cache"
import { redirect } from "next/navigation"

import { createClient } from "@/lib/supabase/server"
// import { getURL } from "@/utils"
import { getClient } from "@/graphql/client/ApolloClient"
import {
  IsEmailTakenDocument,
  MyBasicInfoDocument,
} from "@/graphql/generated/types"

export async function login(formData: FormData) {
  const supabase = createClient()

  const email = formData.get("email") as string
  const password = formData.get("password") as string

  if (!email || !password) {
    return { error_code: "invalid-input" }
  }

  const data = {
    email,
    password,
  }

  const { error } = await supabase.auth.signInWithPassword(data)

  if (error) {
    return { error_code: error.code }
  }

  // const {
  //   data: { user },
  // } = await supabase.auth.getUser()

  const { data: isEmailTakenData } = await getClient().query({
    query: IsEmailTakenDocument,
    variables: {
      email,
    },
  })

  if (!isEmailTakenData?.isEmailTaken) {
    return { error_code: "email-not-found" }
  }

  const { data: myBasicInfoData } = await getClient().query({
    query: MyBasicInfoDocument,
  })

  if (isEmailTakenData?.isEmailTaken && !myBasicInfoData?.me?.username) {
    redirect("/signup?action=account_setup")
  }

  return { error_code: null }

  revalidatePath("/", "layout")
  revalidatePath("/users/[username]", "page")
  redirect("/")
}

export async function resetPassword(email: string) {
  const supabase = createClient()
  const { error } = await supabase.auth.resetPasswordForEmail(email)
  if (error) {
    return { error_code: error.code }
  }
}

export async function updatePassword(password: string) {
  const supabase = createClient()

  const { error } = await supabase.auth.updateUser({ password })

  if (error) {
    return { error_code: error.code }
  }

  const { data: myBasicInfoData } = await getClient().query({
    query: MyBasicInfoDocument,
  })

  if (!myBasicInfoData?.me?.username) {
    redirect("/signup?action=account_setup")
  }

  revalidatePath("/", "layout")
  revalidatePath("/users/[username]", "page")
  redirect("/")
}

// export async function signOut() {
//   const supabase = createClient()
//   const { error } = await supabase.auth.signOut()
//   if (error) {
//     return { error_code: error.code }
//   }

//   revalidatePath("/", "layout")
//   // redirect("/")
// }
