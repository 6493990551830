"use client";

import React from "react";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
// import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import Image from "next/image";
import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import useAuthFormStore from "@/store/useAuthFormStore";
import { FloatingLabelInput } from "../ui/floating-label";
import { Button } from "../ui/button";
import { Separator } from "../ui/separator";
import LoginWithGoogleButton from "./LoginWithGoogleButton";
import { resetPassword, updatePassword } from "@/app/(auth)/login/actions";
import ButtonLoader from "../shared/ButtonLoader";
import { useRouter } from "next/navigation";
// import { Turnstile } from "@marsidev/react-turnstile"
import PasswordResetEmailSent from "./PasswordResetEmailSent";
type PasswordResetProps = {
  action: "forgot_password" | "email_sent" | "update_password" | "updated";
};
const PasswordReset = ({
  action
}: PasswordResetProps) => {
  if (action === "forgot_password") {
    return <ResetPasswordForm />;
  }
  return <div className="flex flex-col gap-8" data-sentry-component="PasswordReset" data-sentry-source-file="PasswordReset.tsx">
      {/* {action !== "email_sent" && (
        <div className="flex items-center gap-3">
          <Avatar className="h-14 w-14">
            <AvatarImage src={avatarUrl as string} />
            <AvatarFallback>U</AvatarFallback>
          </Avatar>
          <div className="flex flex-col gap-1">
            <span className="text-lg font-semibold leading-6 text-white">
              {myBasicInfoData?.me?.username}
            </span>
            <span className="text-base font-medium leading-4 text-white/40">
              User account
            </span>
          </div>
        </div>
       )} */}

      {action === "email_sent" && <PasswordResetEmailSent />}

      {action === "update_password" ? <NewPasswordForm /> : null}

      {action === "updated" ? <PasswordUpdatedNotice /> : null}
    </div>;
};
export default PasswordReset;
const emailFormSchema = z.object({
  email: z.string().email({
    message: "Please enter a valid email address"
  })
});

// const TURNSTILE_SITE_KEY = process.env.NEXT_PUBLIC_TURNSTILE_SITE_KEY!

export const EmailPasswordResetForm = () => {
  const email = useAuthFormStore(state => state.email);
  const setEmail = useAuthFormStore(state => state.setEmail);
  // const [captchaToken, setCaptchaToken] = React.useState("")

  const router = useRouter();
  const [isLoading, setIsLoading] = React.useState(false);
  const form = useForm<z.infer<typeof emailFormSchema>>({
    resolver: zodResolver(emailFormSchema),
    mode: "onChange",
    defaultValues: {
      email: email
    }
  });
  async function onSubmit(values: z.infer<typeof emailFormSchema>) {
    setIsLoading(true);
    setEmail(values.email);
    await resetPassword(values.email);
    router.push("/login?reset_password=true&action=email_sent");
  }
  return <>
      {/* <Turnstile
        siteKey={TURNSTILE_SITE_KEY}
        onSuccess={(token) => {
          setCaptchaToken(token)
        }}
       /> */}
      <Form {...form} data-sentry-element="Form" data-sentry-source-file="PasswordReset.tsx">
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <FormField control={form.control} name="email" render={({
          field
        }) => <FormItem>
                <FormControl>
                  <FloatingLabelInput isInvalid={form.getFieldState("email").invalid} id="email" label="Email" className="sm:w-lg" {...field} />
                </FormControl>
                <FormMessage className="!mt-0 text-[#ce6565]" />
              </FormItem>} data-sentry-element="FormField" data-sentry-source-file="PasswordReset.tsx" />
          <Button disabled={isLoading} variant="primary" className="mt-9 h-12" type="submit" data-sentry-element="Button" data-sentry-source-file="PasswordReset.tsx">
            {isLoading ? <ButtonLoader /> : "Submit"}
          </Button>
        </form>
      </Form>

      <div className="my-9 flex max-w-[45.5%] items-center gap-2.5">
        <Separator className="bg-[#bdbdbd]/10" data-sentry-element="Separator" data-sentry-source-file="PasswordReset.tsx" />
        <span className="text-white/40">or</span>
        <Separator className="bg-[#bdbdbd]/10" data-sentry-element="Separator" data-sentry-source-file="PasswordReset.tsx" />
      </div>

      <div className="mt-9 flex flex-col gap-4 text-base">
        <LoginWithGoogleButton data-sentry-element="LoginWithGoogleButton" data-sentry-source-file="PasswordReset.tsx" />
      </div>
    </>;
};
const ResetPasswordForm = () => {
  // const mode = useAuthFormStore((state) => state.mode)
  // const setMode = useAuthFormStore((state) => state.setMode)

  return <div data-sentry-component="ResetPasswordForm" data-sentry-source-file="PasswordReset.tsx">
      <h3 className="text-[28px] sm:text-4xl font-semibold leading-[44px] text-white">
        Forgot Password?
      </h3>
      <p className="mb-8 mt-2 sm:mt-4 text-[#e6e6fa]/80 sm:text-white/40 font-medium leading-6">
        Enter your email, and we&apos;ll send you a verification link to reset
        your password
      </p>

      <EmailPasswordResetForm data-sentry-element="EmailPasswordResetForm" data-sentry-source-file="PasswordReset.tsx" />
    </div>;
};
const passwordSchema = z.string().min(8, {
  message: "Password must be at least 8 characters"
});
const newPasswordFormSchema = z.object({
  password: passwordSchema,
  confirmPassword: z.string().min(1, {
    message: "Please verify your new password"
  })
}).refine(data => data.password === data.confirmPassword, {
  message: "Passwords don't match",
  path: ["confirmPassword"]
});
export const NewPasswordForm = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const router = useRouter();
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const form = useForm<z.infer<typeof newPasswordFormSchema>>({
    resolver: zodResolver(newPasswordFormSchema),
    mode: "onChange",
    defaultValues: {
      password: "",
      confirmPassword: ""
    }
  });
  async function onSubmit(values: z.infer<typeof newPasswordFormSchema>) {
    setIsLoading(true);
    setErrorMessage("");
    const data = await updatePassword(values.password);
    if (data?.error_code === "weak_password") {
      setErrorMessage("This password was exposed in a data breach. Please choose another.");
      setIsLoading(false);
      return;
    } else if (data?.error_code) {
      setErrorMessage("Something went wrong. Please try again.");
      setIsLoading(false);
      return;
    } else {
      setErrorMessage("");
      setIsLoading(false);
      router.push("?reset_password=true&action=updated");
    }
    setIsLoading(false);
  }
  // const password = form.watch("password")

  return <Form {...form} data-sentry-element="Form" data-sentry-component="NewPasswordForm" data-sentry-source-file="PasswordReset.tsx">
      <h4 className="text-[28px] mt-1 -mb-4 leading-[44px] text-white font-semibold">
        Reset Your Password
      </h4>

      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormField control={form.control} name="password" render={({
        field
      }) => <FormItem>
              <FormLabel className=" max-sm:hidden text-[#e6e6fa]/80 text-xs font-normal">
                New Password
              </FormLabel>
              <FormControl>
                <FloatingLabelInput id="password" type="password" label="Password" className="sm:w-96" isInvalid={form.getFieldState("password").isTouched && form.getValues().password.length === 0} {...field} />
              </FormControl>
              <FormMessage className="!mt-1" />
            </FormItem>} data-sentry-element="FormField" data-sentry-source-file="PasswordReset.tsx" />

        <FormField control={form.control} name="confirmPassword" render={({
        field
      }) => <FormItem className="mt-4 sm:mt-6">
              <FormLabel className=" max-sm:hidden text-[#e6e6fa]/80 text-xs font-normal">
                Confirm Password
              </FormLabel>
              <FormControl>
                <FloatingLabelInput id="password" type="password" label="Password" className="sm:w-96" isInvalid={form.getFieldState("password").isTouched && form.getValues().password.length === 0} {...field} />
              </FormControl>
              <FormMessage role="alert" className="!mt-0" />
            </FormItem>} data-sentry-element="FormField" data-sentry-source-file="PasswordReset.tsx" />

        {errorMessage && <p className="text-red-400 text-sm mt-2">{errorMessage}</p>}
        {/* 
         {form.getFieldState("password").isTouched &&
         form.getValues().password.length === 0 ? (
          <span className="mt-0 text-[0.8rem] font-medium text-[#ce6565]">
            Please enter a valid password
          </span>
         ) : null}
         <PasswordChecklist password={password} /> */}

        <Button disabled={isLoading} variant="primary" className="!mt-9 h-12" type="submit" data-sentry-element="Button" data-sentry-source-file="PasswordReset.tsx">
          {isLoading ? <ButtonLoader /> : "Update Password"}
        </Button>
      </form>
    </Form>;
};
export const PasswordUpdatedNotice = () => {
  const handleClick = () => {
    window.open("/", "_self");
  };
  React.useEffect(() => {
    setTimeout(() => {
      window.open("/", "_self");
    }, 1000);
  }, []);
  return <div data-sentry-component="PasswordUpdatedNotice" data-sentry-source-file="PasswordReset.tsx">
      <h3 className="text-[28px] font-semibold leading-[44px] text-white">
        Your password has been updated succesfully{" "}
        <Image className="ml-1 inline-block" src="/icons/shared/tick.svg" width={24} height={24} alt="tick" data-sentry-element="Image" data-sentry-source-file="PasswordReset.tsx" />
      </h3>
      {/* <p className="mt-3 text-base text-[#646E79] leading-6">
        For security purposes, we&apos;ve sent an email to your account to
        verify the password change.
       </p> */}
      <Button className="mt-6 h-12" variant="primary" onClick={handleClick} type="button" data-sentry-element="Button" data-sentry-source-file="PasswordReset.tsx">
        Continue to Kaguya
      </Button>
    </div>;
};