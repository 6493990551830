"use client";

import Image from "next/image";
import React from "react";
import { Button } from "../ui/button";
import useAuthFormStore from "@/store/useAuthFormStore";
import { redirect } from "next/navigation";
// import { Turnstile } from "@marsidev/react-turnstile"
import { resetPassword } from "@/app/(auth)/login/actions";
import ButtonLoader from "../shared/ButtonLoader";

// const TURNSTILE_SITE_KEY = process.env.NEXT_PUBLIC_TURNSTILE_SITE_KEY!

const PasswordResetEmailSent = () => {
  const email = useAuthFormStore(state => state.email);
  const [isLoading, setisLoading] = React.useState(false);
  const [countdown, setCountdown] = React.useState(30);
  const [isDisabled, setIsDisabled] = React.useState(true);
  // const [captchaToken, setCaptchaToken] = React.useState("")

  React.useEffect(() => {
    if (isDisabled) {
      const timer = setInterval(() => {
        setCountdown(prev => {
          if (prev <= 1) {
            setIsDisabled(false);
            clearInterval(timer);
            return 30;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [isDisabled]);
  if (!email) {
    redirect("/signup");
  }
  const handleResend = async () => {
    setisLoading(true);
    const formData = new FormData();
    formData.append("email", email);
    await resetPassword(email);
    setisLoading(false);
    setIsDisabled(true);
    setCountdown(30);
  };
  if (!email) {
    redirect("/login");
  }
  return <>
      {/* <Turnstile
        siteKey={TURNSTILE_SITE_KEY}
        onSuccess={(token) => {
          setCaptchaToken(token)
        }}
       /> */}
      <div className="space-y-10 max-sm:mt-16 sm:space-y-4 flex flex-col items-center">
        <Image src="/icons/sign-up/thankyou-signup.png" width={114} height={100} alt="thanks for signing up" data-sentry-element="Image" data-sentry-source-file="PasswordResetEmailSent.tsx" />
        <p className="text-2xl sm:text-[28px] text-center w-full font-semibold text-white leading-10">
          Password Reset Email Sent
        </p>

        <p className="font-normal sm:font-medium max-w-[402px] max-sm:!mt-3 max-sm:text-[#e0e0e0] px-2 text-center text-lg  sm:text-base">
          Click the link we sent to{" "}
          <span className="font-medium text-link">{email}</span>. Didn&apos;t
          get it? Resend again.
        </p>

        <Button onClick={handleResend} disabled={isDisabled} variant="primary" className="!mt-9 h-12 max-sm:!font-medium" data-sentry-element="Button" data-sentry-source-file="PasswordResetEmailSent.tsx">
          {isLoading ? <ButtonLoader /> : `Resend ${isDisabled ? ` in ${countdown} seconds` : ""}`}
        </Button>
      </div>
    </>;
};
export default PasswordResetEmailSent;